@import "../../../styles.scss";

$divider-color: rgba(255, 255, 255, 0.34);

.CheckoutTotal {
  width: 410px;
  height: 100%;
  box-sizing: border-box;
  padding: 38px 30px;
  background-color: $strong-red;
  display: flex;
  flex-direction: column;

  .header {
    font-family: $libre-font;
    font-size: 26px;
    color: $white;
    margin-bottom: 8px;
  }

  .line-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $white;
    font-family: $lato-font;
    font-size: 18px;

    >div {
      display: inline-block;
    }

    .label {
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }

    &.subtotal {
      padding: 72px 0 30px 0;
      border-top: 1px solid $divider-color;
      border-bottom: 1px solid $divider-color;
    }

    &.discount {
      padding: 30px 0;
      border-bottom: 1px solid $divider-color;
    }

    &.total {
      padding-top: 39px;
      border-top: 1px solid $divider-color;

      .label {
        font-size: 14px;
      }

      .value {
        font-size: 30px;
        font-family: $libre-font;
        font-weight: bold;
      }
    }
  }

  .add-promo {
    padding: 60px 0;
  }

  .add-promo, .expired-items {
    visibility: hidden;
  }

  .add-promo, .expired-items {
    margin-bottom: auto;

    .error-msg {
      margin-bottom: 10px;
      font-family: $lato-font;
      font-size: 14px;
      color: $white;
      font-weight: bold;
      display: block;
      visibility: hidden;

      div {
        color: $red;
        background-color: $white;
        border-radius: 18px;
        height: 18px;
        width: 18px;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
      }
    }

    &.error {
      .error-msg {
        visibility: visible;
      }
    }

    > div:last-of-type {
      display: flex;

      > input, > button {
        display: inline-block;
      }

      > button {
        font-size: 16px;
        width: 90px;
      }

      input {
        font-family: $lato-font;
        font-size: 16px;
        height: 52px;
        box-sizing: border-box;
        padding: 15px 21px;
        outline: none;
        border: none;
        flex-grow: 1;

        &::placeholder {
          font-family: $lato-font;
          font-size: 16px;
        }
      }

    }
  }

  .disclaimer {
    margin: 30px 0;
    font-family: $lato-font;
    font-size: 14px;
    color: $white;

    a, a:visited, a:active, a:focus {
      color: $white;
    }
  }

  .main-btn {
    margin-bottom: 40px;
    text-align: center;

    >button {
      width: 100%;
      font-size: 16px;
    }
  }

  .continue-btn {
    text-align: center;
    margin-bottom: 10px;

    a, a:visited, a:active, a:focus {
      font-family: $lato-font;
      font-size: 14px;
      text-transform: uppercase;
      color: $white;
    }
  }

  .git-checkbox {
    text-align: center;
    margin-bottom: 10px;
    color: white;
    margin: auto auto 10px auto;
    display: flex;
    font-family: $lato-font;
    font-size: 14px;
    text-transform: uppercase;
  }

  &.step1 {
    .disclaimer {
      visibility: hidden;
    }
  }

  &.step2 {
    .continue-btn, .add-promo {
      visibility: hidden;
    }
  }
}
