@import "../../../styles.scss";

.Tile {
  border: 0.5px solid $grey-4;
  display: inline-block;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }

  .metadata {
    padding: 22px 24px 0 24px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .name {
      font-family: $libre-font;
      font-size: 20px;
      margin-bottom: 3px;
      text-align: left;
    }

    .date {
      font-family: $lato-font;
      font-size: 16px;
      margin-bottom: auto;
      text-transform: uppercase;
      text-align: left;
      padding-bottom: 45px;
    }

    .description {
      font-family: $lato-font;
      font-size: 16px;
      margin: 5px 0 auto 0;
      text-align: left;
      padding-bottom: 25px;
    }

    .actions {
      text-align: left;

      button {
        display: inline-block;
        width: 132px;
        font-size: 14px;
        line-height: 1;
        padding: 17px 13px;
        margin-bottom: 24px;

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }
}
