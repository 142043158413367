@import "../../styles.scss";

.Hero {
  width: 100%;
  font-family: $libre-font;
  margin-bottom: 25px;

  .image {
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }

  .header {
    font-size: 50px;
    margin-bottom: 15px;
    font-style: italic;
  }

  .description {
    font-size: 17px;
    max-width: 90%;
    margin-bottom: 25px;

    p {
      line-height: 1.57;
    }
  }

  .geolocation-message {
    color: $red;
    font-size: 17px;
  }

  .how_button, .add_all_button {
    margin-right: 25px;
  }

  button {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Hero {
    margin-bottom: 0;

    .image {
      margin-bottom: 25px;
    }
    .header {
      font-size: 27px;
      margin: 0 $mobile-page-padding 20px;
    }

    .description {
      margin-left: 16px;
      margin-right: 16px;
      font-size: 14px;
      line-height: 1.57;
      margin: 0 16px 17px;
    }

    .geolocation-message {
      font-size: 14px;
      margin: 0 16px 17px;
    }

    .how_button {
      margin-left: 16px;
      margin-right: 0;
    }

    .add_all_button {
      margin-right: 0;
    }

    .how_button, .add_all_button {
      margin-bottom: 16px;
    }

    .already_purchased_button {
      margin: 0 16px 30px;
    }
  }
}