@import "../../styles.scss";

.Event {
  width: 100%;
  border-bottom: 1px solid $grey-4;
  display: grid;
  grid-template-columns: 350px auto;
  padding: 50px 0;
  grid-column-gap: 45px;

  &:first-of-type {
    border-top: 1px solid $grey-4;
  }

  .image {
    grid-column: 1;
    height: 350px;
    width: 350px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .metadata {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    position: relative;

    .mobile-image {
      display: none;
      display: block;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .availability {
      color: $red;
      font-family: $lato-font;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: -20px;
      text-transform: uppercase;
      letter-spacing: 1.17px;
    }

    .name {
      font-family: $libre-font;
      font-size: 32px;
      font-style: italic;
      margin-bottom: 6px;
      word-wrap: break-word;
      overflow-x: auto;
      overflow-y: hidden;
    }
    .secondary-title {
      font-family: $libre-font;
      font-size: 17px;
      margin-bottom: 24px;
    }
    .date {
      font-family: $lato-font;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 18px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    .description {
      font-family: $libre-font;
      font-size: 17px;
      margin-bottom: auto;
      max-width: 70%;
      line-height: 25px;
    }

    .Price {
      margin: 19px 0 26.5px 0;
    }

    .actions {
      button {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Event {
    display: block;
    .image {
      display: none;
    }

    .metadata {
      display: grid;
      grid-template-columns: 13rem auto;

      .mobile-image {
        display: block;
        height: 13rem;
        grid-column: 1;
        grid-row: 1 / span 4;
      }

      .availability {
        position: relative;
        top: 0;
        grid-column: 2;
        grid-row: 1;
        font-size: 1rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
      }

      .name {
        font-size: 2rem;
        margin-bottom: 0;
        margin-left: 1rem;
        max-width: 20rem;
        grid-column: 2;
        grid-row: 2;
      }

      .secondary-title {
        grid-column: 2;
        grid-row: 3;
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      .date {
        margin-left: 1rem;
        grid-column: 2;
        grid-row: 4;
      }

      .description {
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 100%;
        grid-row: 5;
        grid-column: 1 / span 2;
      }

      .Price {
        margin-top: 1rem;
        margin-bottom: 1rem;
        grid-row: 6;
        grid-column: 1;
      }

      .actions {
        grid-row: 7;
        grid-column: 1 / span 2;

        .Button {
          margin-bottom: 1rem;
          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Event {
    padding: 24px 0;
    .metadata {
      grid-template-columns: 13rem auto;
      .availability {
        font-size: 1rem;
      }

      .name {
        font-size: 1.7rem;
      }

      .date {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .Event {
    padding: 24px 0;
    .metadata {
      grid-template-columns: 8rem auto;
      .mobile-image {
        height: 8rem;
      }

      .availability {
        font-size: 10px;
        line-height: 1;
        margin-bottom: 8px;
      }

      .name {
        font-size: 22px;
        line-height: 1.45;
      }

      .secondary-title {
        font-size: 14px;
      }

      .date {
        font-size: 12px;
      }

      .description {
        font-size: 14px;
        line-height: 1.57;
      }
    }
  }
}
