@import "../../styles.scss";

.NoStreamingModal-modal {
  &-content {
    padding: 40px 70px;
    margin: calc(20vh) auto 0;
    width: 486px;
    height: fit-content;
    align-items: center;
  }

  &-title {
    font-family: $libre-font;
    font-size: 30px;
    line-height: normal;
  }

  &-message {
    margin-top: 20px;
    font-family: $lato-font;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
  }

  &-actions {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    .Button {
      margin-bottom: 0 !important;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .NoStreamingModal-modal {
    &-content {
      width: 400px;
      padding: 32px 35px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .NoStreamingModal-modal {
    &-content {
      width: 100%;
      padding: 25px 16px;
    }
  }
}
