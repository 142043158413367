@import "../../styles";
@import "../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../node_modules/slick-carousel/slick/slick-theme.css";

.Carousel {
  width: 100%;
  text-align: center;
  border-top: 1px solid $grey-4;
  padding: 45px 0 70px 0;
  position: relative;

  .header {
    font-family: $libre-font;
    margin-bottom: 45px;
  }

  .items {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    .Tile {
      width: calc(33% - 30px);

      &:not(:last-child) {
        margin-right: 45px;
      }
    }
    .slick-list {margin: 0 -10px;}
    .slick-slide>div {padding: 0 10px;}
    .slick-dots {
      bottom: -40px;
      li {
        margin: 0;
        &:not(.slick-active) {
          button::before {
            opacity: 1;
            color: $grey-2;
          }
        }
      }
      .slick-active {
        button::before {
          opacity: 1;
        }
      }
      button::before {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Carousel {
    padding: 40px 0 70px;
    .header {
      margin-bottom: 24px;
    }
    .items {
      display: block;

      .Tile {
        width: 100%;
      }
    }
  }
}
