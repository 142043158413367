@import "../../styles.scss";

.login-status {
  &-container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    &:hover .login-status-menu {
      visibility: visible;
      opacity: 1;
    }
    .selected {
      position: relative;
      top: 1px;
    }
  }
  &-menu {
    position: fixed;
    top: 50px;
    height: 50px;
    box-shadow: -3px 4.5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease;
    width: 100%;
  }
  &-user, &-logout {
    @include font($fontFamily: $lato-font, $size: 14px, $weight: bold, $color: $black, $lh: normal);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    svg {
      margin: 0 10px;
      width: 23px;
      height: 23px;
    }
  }
  &-logout {
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .login-status {
    &-container {
      height: auto;
      display: block;
      position: relative;
      &:hover .login-status-menu {
        visibility: visible;
        opacity: 1;
      }
    }
    &-menu {
      position: static;
      top: 0;
      visibility: visible;
      opacity: 1;
      box-shadow: none;
      height: auto;
    }
    &-user, &-logout {
      font-size: 12px;
      svg {
        margin: 0 5px 0 0;
        width: 16px;
        height: 16px;
      }
    }
    &-user {
      margin-bottom: 10px;
    }
  }
}
