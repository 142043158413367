@import "../../styles.scss";

.Cart {
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    .header {
      font-family: $libre-font;
      font-size: 30px;
      margin-bottom: 17px;
    }

    .message {
      font-family: $lato-font;
      font-size: 20px;
      margin-bottom: 33px;
      max-width: unquote("max(350px, 44%)");
      text-align: center;
    }
  }

  &.populated {
    .message {
      font-family: $lato-font;
      font-size: 16px;
      margin-bottom: 40px;
      max-width: unquote("max(660px, 67%)");
    }
  }

  .error-msg {
    display: none;

    &.error {
      display: block;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Cart {
    grid-template-columns: auto;
    padding: 40px 16px 60px;

    &.populated {
      .info {
        grid-column: 1;
        grid-row: 1;

        .CartItem {
          height: auto;
        }
      }

      .total {
        grid-column: 1;
        grid-row: 2;
        margin-top: 6rem;

        .CheckoutTotal {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .Cart {
    grid-template-columns: auto;
    padding: 40px 16px 60px;

    &.empty {
      .header {
        text-align: center;
      }
    }

    &.populated {
      .info {
        grid-column: 1;
        grid-row: 1;

        .CartItem {
          height: auto;
          grid-template-columns: auto;
        }
      }

      .total {
        grid-column: 1;
        grid-row: 2;
        margin-top: 6rem;

        .CheckoutTotal {
          width: 100%;
          padding: 38px 16px;
        }
      }
    }
  }
}
