@import "../../styles.scss";

.Footer {
  width: 100%;
  background-color: $black;

  .content {
    width: 100%;
    height: 100%;
    max-width: $breakpoint-desktop-max;
    padding: 46px $header-footer-padding;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-row-gap: 10px;
    margin: 0 auto;
  }

  li {
    display: inline;
    list-style-type: none;
  }

  .links {
    grid-row: 1;
    grid-column: 1;
    padding: 0;
    margin: 0;
    text-align: left;

    li:not(:last-child) {
      margin-right: 25px;
    }

    a {
      border-bottom: 1px solid $black;

      &, &:visited, &:active, &:focus {
        font-family: $lato-font;
        font-weight: bold;
        font-size: 12px;
        text-decoration: none;
        text-transform: uppercase;
        color: $white;
      }

      &.selected {
        text-decoration: underline;
      }

      &:hover {
        border-color: $white;
        transition: border-color .2s;
      }
    }
  }

  .copyright {
    grid-row: 2;
    grid-column: 1;
    font-family: $lato-font;
    font-size: 12px;
    color: $white;
    text-align: left;
  }

  .social-links {
    grid-row: 1/3;
    grid-column: 2;
    padding: 0;
    margin: 0;
    text-align: right;

    li {
      height: 24px;
      width: 24px;
      margin: .5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-size: 100%;

      &.facebook {
        background-image: url("../../assets/facebook.png");
      }
      &.twitter {
        background-image: url("../../assets/twitter.png");
      }
      &.instagram {
        background-image: url("../../assets/instagram.png");
      }
      &.youtube {
        background-image: url("../../assets/youtube.png");
      }

      a {
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
      }

      svg {
        height: 14px;
        top: 5px;
        position: relative;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Footer .content {
    grid-template-columns: auto;
    padding: 24px $mobile-page-padding;
    grid-row-gap: 24px;

    .social-links {
      grid-column: 1;
      grid-row: 1;
      text-align: left;

      li:first-child {
        margin-left: 0rem;
      }
    }

    .links {
      grid-row: 2;

      li {
        a {
          font-size: 10px !important;
        }
      }
    }

    .copyright {
      grid-row: 3;
    }
  }
}
