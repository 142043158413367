@import "../../styles";

.App > .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: $breakpoint-desktop-max;
  width: 100%;
  margin: 0 auto;
}
