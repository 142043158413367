@import "../../styles.scss";

.FullContentMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-width: 660px;
  margin: 0 auto;
  padding: 0 $mobile-page-padding;
  &-title {
    font-family: $lato-font;
    font-size: 25px;
    font-weight: bold;
    color: $black;
    text-align: center;
  }
  &-body {
    font-family: $libre-font;
    font-size: 18px;
    line-height: 1.57;
    color: $black;
    margin-top: 25px;
    text-align: center;

    > a {
      color: $black;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        border-bottom: 2px solid $black;
      }
    }
  }

  &.geo {
    margin: 120px auto 80px;
  }

  &.no-events {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 120px 0;
    border-top: 1px solid $grey-4;
    border-bottom: 1px solid $grey-4;

    .FullContentMessage {
      &-title, &-body {
        max-width: 660px;
      }
    }
  }
}
