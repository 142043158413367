@import "../../styles.scss";

.Overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $mobile-page-padding;
  box-sizing: border-box;
  z-index: 99;
}

.text {
  font-family: $lato-font;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.67px;
  text-align: center;
  color: $white;
  text-transform: uppercase;
  margin: 0;
  z-index: 9999;
}
