@import "../../styles.scss";

.Header {
  width: 100%;
  height: 50px;
  position: sticky;
  top: 0;
  box-shadow: 0 0.7px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: $white;
  z-index: 100;

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: $breakpoint-desktop-max;
    padding: 0 $header-footer-padding;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    height: 100%;
  }

  .nav-humburger-button {
    display: none;
    width: 1.3rem;

    svg {
      width: 1.3rem;
    }
  }

  #mobile-menu {
    display: none;
    width: 100%;
    padding: 20px $mobile-page-padding;
    position: absolute;
    top: 34px;
    background-color: $white;

    li {
      display: block;
      margin-bottom: 10px;
      a {
        font-size: 12px;
        text-decoration: none;
      }
    }
  }

  a {
    &, &:visited, &:active, &:focus {
      font-family: $lato-font;
      font-weight: bold;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      color: $black-1;
      padding: 2px 0;
    }

    &.selected {
      border-bottom: 2px solid $black-1;
    }
  }

  .menu, .logo {
    display: flex;
    align-items: center;
  }

  .menu {
    margin: 0;
    padding: 0;

    li {
      display: inline;
      list-style-type: none;
      margin-right: 36px;
    }

    &.right {
      justify-content: flex-end;
      > div {
        margin-left: 36px;
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

@media screen and (max-width: $breakpoint-tablet-landscape) {
  .Header {
    .menu {
      li {
        margin-right: 16px;
      }
      &.right {
        > div {
          margin-left: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Header .content {
    height: auto;
    padding: 11px 16px;
    grid-template-columns: 36px 1fr 1fr;

    .nav-humburger-button {
      display: block;
    }

    .logo {
      width: 173px;
    }

    .menu {
      &:not(.right) {
        display: none;
      }
      &.right {
        .login-status-container {
          display: none;
        }
      }
    }
  }
}
