@import "../../styles.scss";

.Button {
  display: inline-block;
  font-family: $lato-font;
  font-size: 14px;
  padding: 17px 13px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:hover {
    transition: border-color .2s ease,background-color .2s ease;
  }

  &, &.white {
    color: $black;
    background-color: transparent;
    border: 1px solid $black;

    &:hover:not(.no-react) {
      background-color: $black;
      color: $white;
    }
  }

  &.red {
    color: $white;
    background-color: $red;
    border: 1px solid $red;

    &:hover:not(.no-react) {
      background-color: $white;
      color: $red;
    }
  }

  &.black {
    color: $white;
    background-color: black;
    border: 1px solid $black;

    &:hover:not(.no-react) {
      background-color: $white;
      color: $black;
    }
  }
  &.short {
    width: 136px;
  }
  &.medium {
    width: 155px;
  }
  &.large {
    width: 177px;
  }
  &.xlarge {
    width: 230px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Button {
    &.large {
      width: 160px;
    }
  }
}
