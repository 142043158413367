@import "../../styles.scss";

.EventPage {
  padding: 0 $page-padding 60px $page-padding;
  font-stretch: normal;
  font-style: normal;
  color: $black-1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .start-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 101;

    &-content {
      padding: 40px 70px;
      margin: calc(20vh) auto 0;
      width: 486px;
      height: 302px;
      display: flex;
      flex-direction: column;
      background-color: $white;
      box-sizing: border-box;
    }

    &-title {
      font-family: $libre-font;
      font-size: 30px;
      line-height: normal;
    }

    &-message {
      margin-top: 20px;
      font-family: $lato-font;
      font-size: 20px;
      line-height: 1.4;
    }

    &-actions {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .carousel-container {
    .Carousel .header {
      font-family: $libre-font;
      font-size: 30px;
      margin-bottom: 45px;
    }
  }
}

.event-area-desc {
  position: relative;
  display: inline-block;
  margin-bottom: 80px;
}

.carousel-area {
  width: auto;
  margin-top: 40px;
}

.player-area {
  margin-bottom: 50px;
  position: relative;
  img {
    width: 100%;
  }
  .player-area-overlay, .start-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .start-overlay {
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .content {
    max-width: 100% !important;

    .EventPage {
      padding: 0;
      .event-area-desc {
        .player-area {
          .Overlay {
            .text {
              font-size: 14px;
            }
          }
        }
        .event-area {
          width: auto;
          padding: 0 $mobile-page-padding;

          .text-area {
            width: auto;

            .event-name-title {
              font-size: 30px;
              margin-bottom: 7px;
            }

            .event-name-subtitle {
              font-size: 14px;
            }

            .event-live-date-title {
              font-size: 14px;
            }
          }
        }
      }
      .carousel-area {
        padding: 0 $mobile-page-padding;
      }
      .carousel-container {
        padding: 0 $mobile-page-padding;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .EventPage {
    .start-popup {
      &-content {
        width: 400px;
        padding: 32px 35px;
      }
    }
    .carousel-container {
      .Carousel .header {
        font-size: 20px;
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .EventPage {
    .start-popup {
      &-content {
        width: 100%;
        padding: 25px 16px;
      }
    }
  }
  .player-area {
    .Overlay {
      .text {
        font-size: 12px !important;
      }
    }
  }
}
