@import "../../styles.scss";

.CheckoutContainer {
  width: 100%;
  padding: 40px $page-padding 60px $page-padding;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 410px;
  grid-column-gap: 100px;

  .info {
    padding-top: 25px;
    grid-column: 1;

    .header {
      font-family: $libre-font;
      font-size: 50px;
      margin: 34px 0 8px 0;
    }
  }

  .total {
    grid-column: 2;
    background-color: $strong-red;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .CheckoutContainer {
    .info {
      .header {
        font-size: 26px;
      }
    }
  }
}
