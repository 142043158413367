@import "../../styles.scss";

.CartItemContainer {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  &:last-of-type {
    margin-bottom: 300px;
  }

  .CartItemOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 50%;
    background-color: grey;
  }

  .CartItem {
    width: 100%;
    display: grid;
    grid-template-columns: 226px auto;
    min-height: 226px;
    grid-template-rows: 1fr;
    border: 1px solid $grey-3;
    box-sizing: border-box;

    .image {
      grid-row: 1/3;
      grid-column: 1;
      height: 226px;
      width: 226px;
      overflow: hidden;

      img {
        height: 100%;
        position: relative;
        top: -1px;
        left: -1px;
      }
    }

    .meta {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      padding: 25px;
      grid-column-gap: 10px;
    }

    .info {
      grid-row: 1/3;
      grid-column: 1;

      .error-msg-item {
        color: $red;
        font-family: $lato-font;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 10px;
        display: none;

        &.error {
          display: block;
        }
      }

      .name {
        font-family: $libre-font;
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 3px;
        word-break: break-word;
      }

      .secondary-title {
        font-family: $libre-font;
        font-size: 18px;
        margin-bottom: 15px;
      }

      .date {
        font-family: $lato-font;
        font-size: 18px;
        text-transform: uppercase;
      }
    }

    .remove-btn {
      grid-column: 2;
      grid-row: 1;
      font-family: $lato-font;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      text-align: right;
    }

    .price {
      grid-column: 2;
      grid-row: 2;
      font-family: $lato-font;
      font-size: 18px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .CartItemContainer {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .CartItemContainer {
    .CartItem {
      .meta {
        padding: 10px;
        grid-column-gap: 0;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .CartItemContainer {
    .CartItem {
      .image {
        width: 100%;
        height: auto;

        img {
          height: auto;
          object-fit: cover;
          width: 100%;
          top: 0;
          left: 0;
        }
      }
      .meta {
        padding: 25px;
        grid-column-gap: 10px;
      }
    }
  }
}