@import "../../styles";

.HowItWorks {
  padding: 70px 340px 150px $page-padding;

  a, a:visited, a:focus, a:active {
    color: black;
  }

  p {
    margin: 20px 0;
  }

  p, div {
    font-family: $libre-font;
    font-size: 17px;
    line-height: 27px;
  }

  .header {
    font-family: $libre-font;
    font-size: 32px;
    margin-bottom: 40px;
  }

  .subheader {
    text-transform: uppercase;
  }

  section {
    padding-bottom: 25px;
    border-bottom: 1px solid $grey-4;
  }

  .main {
    .step {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  .tech_reqs {
    margin-top: 45px;
    margin-bottom: 45px;

    p:first-of-type {
      margin-top: 5px;
    }
  }

  .faq {
    .question {
      > div {
        font-weight: bold;
      }
      > p {
        margin-top: 0;
      }

      &:not(:last-of-type) {
        margin-bottom: 45px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .HowItWorks {
    padding: 70px $mobile-page-padding;

    .header {
      line-height: unset;
    }
  }
}
