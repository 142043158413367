@import "../../styles.scss";

.EventPage {
  .event-area {
    width: 100%;
    position: relative;

    .text-area {
      width: 90%;
      float: left;

      .actions {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        button {
          display: inline-block;

          &:not(:last-of-type) {
            margin-right: 25px;
          }
        }
      }
    }

    .actions {
      float: right;
      text-align: right;
      button {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: 25px;
        }
      }
    }
  }

  .event-name-title {
    font-family: $libre-font;
    font-size: 45px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    max-width: 75%;
    word-wrap: break-word;
  }

  .event-name-subtitle {
    font-family: $libre-font;
    font-size: 20px;
    font-weight: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .event-secondary-title {
    font-family: $libre-font;
    font-size: 17px;
  }

  .event-live-date-title {
    font-family: $lato-font;
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.88px;
    text-align: left;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 35px;
  }

  .event-description {
    p, div {
      font-family: $libre-font;
      font-size: 17px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      strong {
        font-size: 32px;
        line-height: 0.69;
      }
    }
  }

  .Price {
    margin-bottom: 20px;
  }

  .fake-play-button{
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    width: 100px;
    height: 100px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .EventPage {
    .event-area {
      .text-area {
        .event-description {
          hr {
            width: auto !important;
          }
        }
        .actions {
          display: block;
          right: 16px;
        }
      }
      .actions {
        display: none;
      }
    }
    .event-name-title {
      max-width: 60%;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .EventPage {
    .event-area {
      .text-area {
        .actions {
          position: static;
          text-align: left;
          margin-bottom: 30px;
          button {
            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
          }
        }
      }
      .actions {
        display: none;
      }
    }
    .event-name-title {
      max-width: 100%;
    }
  }
}
