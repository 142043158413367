// Colors
$black: black;
$black-1: #010101;
$white: white;
$red: #c61c1c;
$error-red: #c61c1c;
$grey: #797979;
$grey-1: #aaaaaa;
$grey-2: #d8d8d8;
$grey-3: #979797;
$grey-4: #a7a9ac;
$grey-5: #8b8b8b;
$grey-6: #b6b6b6;
$grey-7: #727272;
$grey-8: #c9c9c9;
$strong-red: #b32827;
$silver: #CCCCCC;

// Fonts
$lato-font: 'Lato', sans-serif;
$libre-font: 'Libre Baskerville', serif;
$roboto-font: 'Roboto', sans-serif;

// Constants
$standard-margin: 30px;
$header-footer-padding: 60px;
$page-padding: 150px;
$mobile-page-padding: 16px;

// Break Points
$breakpoint-mobile: 432px;
$breakpoint-tablet: 776px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-desktop: 1224px;
$breakpoint-desktop-max: 1592px;
$breakpoint-desktop-max-fixed-width: 1960px;

// Styles
.italics {
  font-style: italic;
}

// Mixins
@mixin font($fontFamily: false, $size: false, $color: false, $weight: false,  $style: false, $lh: false, $ls: false) {
  @if $fontFamily { font-family: $fontFamily; }
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $style { font-style: $style; }
  @if $lh { line-height: $lh; }
  @if $ls { letter-spacing: $ls; }
}

@mixin flexBox($display: false, $flexDirection: false, $flexFlow: false, $alignItems: false, $justifyContent: false, $flexWrap: false, $flex: false) {
  @if $display { display: $display } @else { display: flex }
  @if $flexDirection { flex-direction: $flexDirection; }
  @if $flexWrap { flex-wrap: $flexWrap; }
  @if $flexFlow { flex-flow: $flexFlow; }
  @if $alignItems { align-items: $alignItems; }
  @if $justifyContent { justify-content: $justifyContent; }
  @if $flex { flex: $flex; }
}

@mixin captionBorder($color: $black, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}

[class$="-modal"] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 101;
}

[class$="-modal-content"] {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-sizing: border-box;
}
