@import "../../styles.scss";

.Confirmation {
  text-align: center;
  padding: 70px $page-padding;

  > img {
    height: 88px;
    margin-bottom: 40px;
  }

  .header {
    font-family: $libre-font;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .message {
    font-family: $lato-font;
    font-size: 20px;
    margin: 0 auto 20px auto;
    max-width: 60%;
    line-height: 1.3;

    & > a {
      cursor: pointer;
      color: $black;
      text-decoration: none;
      &:hover {
        border-bottom: 2px solid $black;
      }
    }
  }

  .contact {
    font-family: $lato-font;
    font-size: 20px;
    margin: 0 auto 30px auto;
    max-width: 60%;
  }

  .Carousel.related {
    margin-top: 70px;
  }

  .Carousel.explore {
    padding-bottom: 0;

    .header {
      margin-bottom: 45px;
    }
  }

  .summary {
    border-top: 1px solid $grey-3;
    padding-top: 40px;
    width: 60%;
    margin: 0 auto 70px auto;

    .summary-header {
      font-family: $lato-font;
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 27px;
    }
    .summary-grid {
      width: 100%;
      text-align: left;

      .line-item {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        > div {
          display: inline-block;
        }

        .key {
          font-family: $lato-font;
          font-weight: bold;
          font-size: 18px;
          width: 55%;
          margin-right: 20px;
        }
        .value {
          font-family: $lato-font;
          font-size: 16px;
          width: calc(45% - 20px);
        }

        &.order {
          .key {
            text-transform: uppercase;
            font-size: 16px;
            color: $grey-5;
          }
        }

        &.total {
          .key {
            text-transform: uppercase;
            font-size: 16px;
            color: $grey-5;
          }
          .value {
            font-family: $roboto-font;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Confirmation {
    padding: 70px $mobile-page-padding;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Confirmation {
    .header {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 25px;
    }

    .message {
      font-size: 16px;
      max-width: none;
    }

    .contact {
      font-size: 16px;
    }

    .Carousel.related {
      margin-top: 40px;
    }
    .Carousel {
      .header {
        margin-top: 0;
      }
    }
  }
}
