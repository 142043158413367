@import "../../styles.scss";

.Home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .events-container {
    padding: 0 $page-padding 60px $page-padding;
    display: flex;
    flex-direction: column;
  }

  .donors {
    margin-top: 60px;
    width: 383px;
    height: auto;
    max-width: 100%;
  }
  .carousel-container {
    padding: 0 $page-padding 70px;

    .Carousel .header {
      font-family: $libre-font;
      font-size: 30px;
      margin-bottom: 45px;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Home {
    .carousel-container {
      padding: 0 $mobile-page-padding;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Home {
    .events-container {
      padding: 0;
    }

    .events {
      padding: 0 $mobile-page-padding;
    }

    .donors {
      padding: 0 $mobile-page-padding;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .carousel-container {
      .Carousel .header {
        font-size: 20px;
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .Home {
    .donors {
      box-sizing: border-box;
    }
  }
}
