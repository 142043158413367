@import "../../styles.scss";

.video-js .vjs-tech {
  position: relative !important;
}

.vjs-dock-text {
  display: none;
}

.player-container {
  width: 100%;
  video-js {
    width: 100%;
    height: auto;

    &.vjs-chromecast-receiver-connected {
      padding-top: 56.25%;
    }
  }
}

.video-js:hover {
  .vjs-big-play-button {
    background: none !important;
  }
}

.vjs-big-play-button {
  background: none !important;
  .vjs-icon-placeholder::before {
    background-image: url("../../assets/btn-play.svg") !important;
    background-size: 100% !important;
    content: "" !important;
  }
}

.vjs-text-track-cue div {
  color: $grey-8 !important;
  background-color: transparent !important;
  @include captionBorder;
}
