@import "../../styles.scss";

.Checkout {
  .server_error {
    color: $red;
    margin: 50px 0 30px 0;
    font-size: 18px;
    font-family: $libre-font;
    font-style: italic;
    display: none;

    &.visible {
      display: block;
    }
  }

  .info {
    .header {
      margin-bottom: 30px;
    }
  }

  .payment_header {
    font-family: $lato-font;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .cards .payment_header, .card .payment_header {
    margin-bottom: 10px;
  }

  section {
    width: 460px;

    &.cards {
      margin-bottom: 35px;

      img {
        display: inline-block;
        height: 20px;
        margin-right: 20px;
      }
    }

    .error-message, .card_errors > div {
      display: none;
      font-family: $roboto-font;
      font-weight: bold;
      font-size: 12px;
      color: $red;
      margin-top: 5px;

      > div {
        color: $white;
        background-color: $red;
        border-radius: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        text-align: center;
        margin-right: 5px;
      }
      span {
        color: $red;
      }
    }

    &.card {
      margin-bottom: 45px;

      .StripeElement--invalid {
        color: red;
      }

      .card_info {
        background-color: $black;
        border-radius: 5px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        .card_errors {
          margin-top: 20px;
          > div {
            margin-top: 5px;
          }
        }

        .row {
          display: flex;
          flex-direction: row;

          .name {
            flex-grow: 1;
            margin-right: 10px;
          }

          .expiry {
            width: 105px;
          }
        }

        .stripe_field {
          position: relative;
          margin-bottom: 10px;

          &.error {
            input {
              color: $error-red;
            }
          }
          label {
            color: $white;
            font-family: $roboto-font;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 10px;

            &:last-of-type {
              position: absolute;
              right: 0;
              font-style: italic;
              top: 6px;
              text-transform: capitalize;
              font-size: 8px;
            }

            &.short {
              display: inline-block;
              width: 40px;
              line-height: 1.5;
              +label:last-of-type {
                top: 3px;
              }
            }
          }

          .stripe_input {
            margin-top: 4px;
            background-color: $white;
            height: 25px;
            padding: 0 5px;
          }

          &.cvv {
            width: 15%;
            min-width: 78px;
            max-width: 90px;
          }

          &.name {
            input {
              margin-top: 4px;
              display: block;
              height: 25px;
              outline: none;
              border: none;
              border-radius: 0;
              width: 100%;
              box-sizing: border-box;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }

  .payment_field {
    margin-bottom: 25px;

    &.error {
      select, input {
        color: $error-red;
        border: 0.5px solid $error-red !important;
      }
      label {
        color: $red;
      }
      .error-message {
        display: block;
      }
    }

    label {
      font-family: $roboto-font;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 10px;
      display: block;
      margin-bottom: 4px;
    }

    input, select {
      width: 100%;
      height: 25px;
      border: 0.5px solid $grey-1;
      outline: none;
      box-sizing: border-box;
      padding: 1px 5px;
    }

    select {
      cursor: pointer;
    }

    .select_wrapper {
      position: relative;

      select {
        appearance: none;
      }

      &:after {
        content: "\25bc";
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 7px;
        transform: scale(1.7, 1);
      }
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Checkout {
    grid-template-columns: auto;
    padding: 40px $mobile-page-padding 60px;

    .info {
      grid-column: 1;
      grid-row: 1;

      .header {
        text-align: center;
      }

      section {
        width: 100%;
        max-width: 460px;
        margin-right: auto;
        margin-left: auto;
      }

      .stripe_field {
        &.expiry {
          width: 127px !important;
        }

        &.cvv {
          width: 19% !important;
        }
      }
    }

    .total {
      grid-column: 1;
      grid-row: 2;
      margin-top: 6rem;

      .CheckoutTotal {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Checkout {
    display: block;
    grid-template-columns: auto;

    .info {

      .header {
        font-size: 2.5rem;
      }

      section {
        width: auto;
      }
    }

    .total {
      margin-top: 6rem;

      .CheckoutTotal {
        width: auto;
        padding: 38px $mobile-page-padding;

        .add-promo {
          .promo-code {
            padding: 15px $mobile-page-padding;
          }
        }
      }
    }
  }
}
