@import "../../../styles.scss";

.CheckoutProgress {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  .bar {
    width: 50%;
    height: 1px;
    background-color: $grey-2;
    position: absolute;
    top: 4px;
    z-index: -1;

    &:nth-of-type(2) {
      left: 50%;
    }

    &.selected {
      background-color: $black;
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;

    .oval {
      height: 7px;
      width: 7px;
      border-radius: 7px;
      border: 1px solid $grey-2;
      background-color: $white;
      margin-bottom: 5px;
    }

    .label {
      font-family: $roboto-font;
      font-size: 10px;
      font-weight: bold;
      color: $grey;
      text-transform: uppercase;
    }

    &.selected {
      .oval {
        border-color: $black;
        background-color: $black;
      }

      .label {
        color: black;
      }
    }

    &.one {
      align-items: flex-start;
    }

    &.three {
      align-items: flex-end;
    }
  }
}
