@import "../../styles.scss";

.Venues-modal {
  align-items: center;

  &-content {
    max-width: 1139px;
    width: 80%;
    height: 85%;
    max-height: 1144px;
    padding: 0 0 0 0.5px;
    font-family: $libre-font;
    text-align: left;
    color: $black;
  }

  &-header {
    padding: 31px 35px 17.5px 72.5px;
    box-shadow: 0 1px 7.5px 0 rgba(0, 0, 0, 0.5);
  }

  [aria-label="close modal"] {
    position: relative;
    width: 32.5px;
    height: 32.5px;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }

    span {
      width: 1.5px;
      height: 100%;
      margin-left: 15px;
      border-radius: 0.5px;
      border: solid 1px $grey-3;
      background-color: $grey-7;
      position: absolute;
      top: 0;
      left: 0;
    }

    span:nth-child(1) {
      transform: rotate(45deg);
    }

    span:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  &-title {
    font-size: 45px;
    font-weight: normal;
    line-height: 1.11;
    margin: 0;
  }

  &-subtitle {
    font-size: 20px;
    line-height: 2.5;
    text-transform: uppercase;
    margin: 0;
  }

  &-date {
    margin: 13px 0 0;
    font-family: $lato-font;
    font-size: 22.5px;
    font-weight: bold;
    letter-spacing: 1.88px;
    text-transform: uppercase;
  }

  &-items {
    flex-grow: 1;
    list-style-type: none;
    margin: 0 23.5px 0 0;
    padding: 13.5px 0 24.5px 74px;
    overflow-y: scroll;
  }

  &-item {
    margin-right: 43.5px;
  }

  hr {
    background-color: $grey-3;
    border: none;
    height: 0.5px;
    margin: 0;
  }

  &-footer {
    padding: 29.5px 42px 35.5px 73.5px;
    box-shadow: 0 1px 7.5px 0 rgba(0, 0, 0, 0.5);
    margin-top: auto;

    p {
      margin: 0;
      font-size: 20px;
      line-height: 2.5;
    }

    a {
      color: $red;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Venues-modal {
    &-content {
      width: 100%;
      margin: 0 20px;
    }
    
    &-header {
      padding: 16.5px 19px 17.5px 39px;
    }

    [aria-label="close modal"] {
      width: 17px;
      height: 17px;
    }

    &-title {
      font-size: 24px;
    }
  
    &-subtitle {
      font-size: 11px;
    }
  
    &-date {
      font-size: 12px;
      margin-top: 7px;
    }

    &-items {
      margin-right: 12.5px;
      padding: 7px 0 13px 39.5px;
    }

    &-item {
      margin-right: 23px;
    }

    &-footer {
      padding: 16px 22.5px 19px 39px;

      p {
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Venues-modal {
    &-content {
      margin: 0;
      height: 90%;
    }

    &-header {
      padding: 9px 16px 15px 16px;
    }

    &-items {
      padding-left: 16.5px;
      margin-right: 2px;
    }

    &-item {
      margin-right: 14px;
    }

    &-footer {
      padding: 12px 16px 15px 16px;
    }
  }
}
