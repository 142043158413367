@import "../../styles.scss";

.Venue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0 24px 0;

  &-info {
    font-size: 15px;
    line-height: 1.33;
    flex-basis: 40%;
  }

  &-name {
    margin: 0 0 8.5px;
    text-transform: uppercase;
  }

  &-distance {
    font-size: 15px;
    margin: 0;
    opacity: 0.7;
  }

  &-address {
    font-size: 17.5px;
    line-height: 1.14;
    margin-left: 10px;
    flex: 1;
  }

  .Button {
    width: 191.5px;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .Venue {
    margin: 19px 0 13px 0;

    &-info {
      font-size: 11px;
    }

    &-distance {
      font-size: 11px;
    }

    &-address {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .Venue {
    .Button {
      width: auto;
      white-space: nowrap;
    }
  }
}
