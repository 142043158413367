@import "./styles";

html, body, #root, .App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.App {
  &.processing {
    button, a {
      pointer-events: none;
    }
  }
}
